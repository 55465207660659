import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import tutorialVideo from "../../../../video/tutorial.mp4";

const TutorialPage = () => {
  const { t } = useTranslation();

  return (
    <div
      id="tutorial-page"
      className="pin-map-page-container simple-page-container bg-white overflow-y-scroll"
    >
      <div className="pin-map-page-content py-2 px-1">
        {/* <h1 className="show-pin-headline">{t("tutorial.headline")}</h1>
        <p className="gray-text">{t("tutorial.description")}</p> */}
        <div className="">
          <video
            controls
            className="tutorial-video w-100"
            src={tutorialVideo}
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default TutorialPage;
